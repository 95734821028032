import React, { useState, useEffect, useCallback, useRef } from "react";
import { PrevButton, NextButton } from "./EmblaCarouselButtons";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import { mediaByIndex } from "../media";
import "../css/embla.css";

const EmblaCarousel = ({ slides, options = { loop: false } }) => {
  const autoplay = useRef(
    Autoplay(
      { delay: 4000, stopOnInteraction: false },
      (emblaRoot) => emblaRoot.parentElement
    )
  );

  const [emblaRef, emblaApi] = useEmblaCarousel(options, [autoplay.current]);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollNext = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
    autoplay.current.reset();
  }, [emblaApi]);

  const scrollPrev = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
    autoplay.current.reset();
  }, [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.canScrollNext());
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect]);

  return (
    <div className="embla  md:ml-10 w-[320px] sm:w-[515px] md:w-[625px] xl:w-[520px] 2xl:w-[570px]">
      <div
        className="embla__viewport w-[300px] sm:w-[500px] md:w-[600px] xl:w-[480px] 2xl:w-[540px]"
        ref={emblaRef}
      >
        <div className="embla__container">
          {slides.map((index) => (
            <div
              className="embla__slide w-[300px] sm:w-[500px] md:w-[600px] xl:w-[490px] 2xl:w-[560px]"
              key={index}
            >
              <div className="embla__slide__inner  h-[470px] w-[290px] sm:h-[620px]   lg:h-[680px]  sm:w-[480px] md:w-[580px] xl:h-[620px]  2xl:h-[700px] xl:w-[470px] 2xl:w-[540px]  rounded-xl ">
                <img
                  className="embla__slide__img "
                  src={mediaByIndex(index)}
                  alt="Referenzen"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
      <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
    </div>
  );
};

export default EmblaCarousel;
