const Footer = () => {
  return (
    <div className="bg-[#DFE0DF] ">
      <div className="px-6  max-w-6xl 2xl:max-w-[1450px] mx-auto pb-5  text-center">
        <div>
          <h1 className="text-center">© 2022 SELO Parkett- & Fußböden</h1>
        </div>
      </div>
    </div>
  );
};

export default Footer;
