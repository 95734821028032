import Parquet from "../assets/parquet.jpg";

export default function About() {
  return (
    <div>
      <div className="relative bg-indigo-200">
        <div className="absolute inset-0">
          <img className="w-full h-full object-cover" src={Parquet} alt="" />
          <div
            className="absolute inset-0 bg-[#00000087] mix-blend-multiply"
            aria-hidden="true"
          />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-6 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-3xl md:text-4xl font-bold tracking-tight text-white sm:text-5xl sm:tracking-tight lg:text-6xl lg:tracking-tight text-center">
            Ihr Parkett-Profi aus Bocholt
          </h1>
          <p className=" mt-6  text-md md:text-xl text-indigo-100 max-w-3xl mx-auto text-center">
            Als Experten für Parkett und Bodenbeläge in Bocholt ist es für uns
            selbstverständlich, perfekte Handwerksarbeit abzuliefern. Vor der
            Ausführung besprechen wir dabei mit Ihnen alle wichtigen Details.
            Wir sind ihr idealer Partner, wenn es darum geht, Bodenbeläge oder
            hochwertiges Parkett verlegen zu lassen.
          </p>
        </div>
      </div>
    </div>
  );
}
