import About from "../components/About";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Slider from "../components/Slider";
import Uns from "../components/Uns";
import Unsere from "../components/Unsere";

export default function Home() {
  return (
    <>
      <Navbar />
      <About />
      <Unsere />
      <Slider />
      <Uns />
      <Contact />
      <Footer />
    </>
  );
}
