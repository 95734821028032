import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { HiMail, HiPhone } from "react-icons/hi";
import { GoThreeBars } from "react-icons/go";
import { IoMdClose } from "react-icons/io";
import { Link as ScrollEffect } from "react-scroll";
import { Link } from "react-router-dom";
import Selo from "../assets/Selo.png";

const Navbar = () => {
  return (
    <div className="bg-[#3A210D]">
      <div className=" py-2 w-full max-w-7xl 2xl:max-w-[1450px]  mx-auto px-6 sm:px-6 lg:px-8 ">
        <Popover>
          <nav
            className="flex items-center justify-between py-3 md:py-5"
            aria-label="Global"
          >
            <div className="flex items-center flex-1">
              <div className="flex items-center justify-between w-full md:w-auto">
                <Link to="/">
                  <img
                    className="h-12 md:h-14 2xl:h-16 cursor-pointer"
                    src={Selo}
                    alt=""
                  />
                </Link>
                <div className="flex items-center md:hidden">
                  <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-white  focus:outline-none">
                    <span className="sr-only">Open main menu</span>
                    <GoThreeBars size={25} aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="ml-10  items-baseline space-x-9 text-white hidden md:flex 2xl:text-xl  ">
                <ScrollEffect
                  to="leistungen"
                  smooth={true}
                  duration={500}
                  className="cursor-pointer hover:text-[#E2BCA3]"
                >
                  <Link to="/">Leistungen</Link>
                </ScrollEffect>
                <ScrollEffect
                  to="projekte"
                  smooth={true}
                  duration={900}
                  className="cursor-pointer hover:text-[#E2BCA3]"
                >
                  Referenzen
                </ScrollEffect>
                <ScrollEffect
                  to="über"
                  smooth={true}
                  duration={900}
                  className="cursor-pointer hover:text-[#E2BCA3]"
                >
                  Über uns
                </ScrollEffect>
                <ScrollEffect
                  to="kontakt"
                  smooth={true}
                  duration={900}
                  className="cursor-pointer hover:text-[#E2BCA3]"
                >
                  Kontakt
                </ScrollEffect>
              </div>
            </div>
            <div className=" gap-5 xl:gap-10 hidden md:flex text-white">
              <a
                href="mailto:sel.o@gmx.de"
                className="hover:text-[#E2BCA3] p-2 rounded-lg "
              >
                <HiMail size={22} />
              </a>
              <a
                href="tel:+4917670086325"
                className="hover:text-[#E2BCA3] p-2 rounded-lg "
              >
                <HiPhone size={22} />
              </a>
            </div>
          </nav>

          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute z-10 top-0 inset-x-0 p-2.5 transition transform origin-top-right md:hidden"
            >
              <div className="rounded-lg shadow-md bg-[#3A210D] ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="px-4 pt-4 flex items-center justify-between">
                  <div>
                    <Link to="/">
                      <img className="h-8 " src={Selo} alt="" />
                    </Link>
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-black hover:bg-gray-100 focus:outline-none">
                      <IoMdClose className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="px-2 pt-2 space-y-1">
                  <Link to="/">
                    <ScrollEffect
                      to="leistungen"
                      smooth={true}
                      duration={500}
                      className="block px-3 py-2 rounded-md text-base font-semibold text-white hover:text-gray-900 hover:bg-gray-50 cursor-pointer"
                    >
                      Leistungen
                    </ScrollEffect>
                  </Link>

                  <ScrollEffect
                    to="projekte"
                    smooth={true}
                    duration={900}
                    className="block px-3 py-2 rounded-md text-base font-semibold text-white hover:text-gray-900 hover:bg-gray-50 cursor-pointer"
                  >
                    Referenzen
                  </ScrollEffect>
                  <ScrollEffect
                    to="über"
                    smooth={true}
                    duration={900}
                    className="block px-3 py-2 rounded-md text-base font-semibold text-white hover:text-gray-900 hover:bg-gray-50 cursor-pointer"
                  >
                    Über uns
                  </ScrollEffect>
                  <ScrollEffect
                    to="kontakt"
                    smooth={true}
                    duration={900}
                    className="block px-3 py-2 rounded-md text-base font-semibold text-white hover:text-gray-900 hover:bg-gray-50 cursor-pointer"
                  >
                    Kontakt
                  </ScrollEffect>
                </div>
                <div className="pt-4 pb-2 px-4">
                  <a
                    href="tel:+4917670086325"
                    className="w-full flex items-center justify-center px-4 py-2 border rounded-lg shadow-sm text-base font-medium text-white bg-brightnavy hover:bg-grblue"
                  >
                    Jetzt anrufen
                  </a>
                </div>
                <div className="pt-1 pb-4 px-4">
                  <a
                    href="mailto:sel.o@gmx.de"
                    className="w-full flex items-center justify-center px-4 py-2 border rounded-lg shadow-sm text-base font-medium text-white bg-brightnavy hover:bg-grblue"
                  >
                    Jetzt E-Mail senden
                  </a>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </div>
    </div>
  );
};

export default Navbar;
