import media1 from "./media-1.jpg";
import media2 from "./media-2.jpg";
import media3 from "./media-3.jpg";
import media4 from "./media-4.jpg";
import media5 from "./media-5.jpg";
import media6 from "./media-6.jpg";
import media7 from "./media-7.jpg";
import media8 from "./media-8.jpg";
import media9 from "./media-9.jpg";
import media10 from "./media-10.jpg";
import media11 from "./media-11.jpg";
import media12 from "./media-12.jpg";
import media13 from "./media-13.jpg";
import media14 from "./media-14.jpg";
import media15 from "./media-15.jpg";
import media16 from "./media-16.jpg";
import media17 from "./media-17.jpg";
import media18 from "./media-18.jpg";

export const media = [
  media1,
  media2,
  media3,
  media4,
  media5,
  media6,
  media7,
  media8,
  media9,
  media10,
  media11,
  media12,
  media13,
  media14,
  media15,
  media16,
  media17,
  media18,
];
export const mediaByIndex = (index) => media[index % media.length];
