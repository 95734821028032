import React from "react";
import EmblaCarousel from "../js/EmblaCarousel";

const SLIDE_COUNT = 18;
const slides = Array.from(Array(SLIDE_COUNT).keys());

export default function Push() {
  return (
    <div>
      <main>
        <EmblaCarousel slides={slides} />
      </main>
    </div>
  );
}
