import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function Uns() {
  let location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);
  return (
    <div name="über" id="über" className="bg-white ">
      <div className="relative">
        <div className="absolute inset-0">
          <div
            className="absolute inset-0  mix-blend-multiply"
            aria-hidden="true"
          />
        </div>
        <div className="relative max-w-7xl mx-auto px-6 lg:px-8">
          <div className="max-w-7xl mx-auto py-16">
            <h1 className="text-6xl text-center font-bold mb-5 lg:mb-10">
              Über Uns
            </h1>
            <p className="md:text-xl pt-5 text-center text-[#2B2C2B]">
              Holz ist unsere Leidenschaft – und das seit vielen Jahren. Unser
              3-köpfiges Team hat sich auf Parkett und Fußbodenbeläge in Bocholt
              spezialisiert.
            </p>
            <p className="md:text-xl pt-5 text-center text-[#2B2C2B]">
              Mit unserem großen Wissen über Holzverarbeitung und mit Liebe zum
              Detail konnten wir schon viele Kunden begeistern. Für uns hat die
              Zufriedenheit unserer Kunden oberste Priorität. Mit jahrelanger
              Erfahrung führen wir alle Arbeiten mit höchster Qualität aus, um
              ihren Ansprüchen gerecht zu werden. Wir sind stolz auf unsere hohe
              handwerkliche Qualität und unsere Aufmerksamkeit für jedes Detail
              bei jedem Auftrag, den wir ausführen. Wir sind uns bewusst, dass
              Ihr Parkettboden eine kostspielige Investition ist, und wir werden
              ihn nach bestem Wissen und Gewissen und zu Ihrer vollsten
              Zufriedenheit verlegen, reparieren, restaurieren und/oder
              aufarbeiten. Wir sind zuversichtlich, dass Ihr Parkettboden, wenn
              wir fertig sind, ein Blickfang sein wird und Ihren Boden in jeder
              Hinsicht aufwerten wird.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
