import React from "react";
import { AiOutlineDoubleRight } from "react-icons/ai";

export default function Content() {
  return (
    <div className="">
      <h1 className="text-3xl md:text-4xl">
        Unser Service für Bocholt und Umgebung
      </h1>
      <h1 className="pt-5 text-3xl md:text-4xl">
        Zuverlässige Arbeit - schnell und flexibel
      </h1>
      <div className="flex pt-10">
        <div>
          <AiOutlineDoubleRight size={30} />
        </div>
        <div className="pl-6  max-w-2xl lg:max-w-4xl xl:max-w-lg 2xl:max-w-2xl">
          <p className="text-2xl 2xl:text-4xl">Persönliche Erstberatung</p>
          <p className="pt-1 2xl:text-xl">
            SELO-Parkett und Fußböden ist Spezialist für Bodenbeläge in Bocholt.
            Vor der Ausführung beraten wir unsere Kunden ausführlich über die
            Vorgehensweise und Preise. Wir legen neben Kundenzufriedenheit auch
            Wert auf eine professionelle Beratung von Parkett bis hin zu
            Teppichböden. Wir bieten unsren Kunden verschiedene Bodenbelege von
            höchster Qualität. Nach einer kompetenten und ausführlichen Beratung
            erstellen wir Ihnen ein individuelles Angebot, dass genau auf Sie
            zugeschnitten ist.
          </p>
        </div>
      </div>
      <div className="flex pt-14 items">
        <div>
          <AiOutlineDoubleRight size={30} />
        </div>
        <div className="pl-6  max-w-2xl lg:max-w-4xl xl:max-w-lg 2xl:max-w-2xl">
          <p className="text-2xl 2xl:text-4xl">Kostenvoranschlag</p>
          <p className="pt-1 2xl:text-xl">
            Bei uns kaufen Sie nicht die Katze im Sack, Sie erhalten nach der
            Erstberatung einen Kostenvoranschlag, was das Verlegen eines neuen
            Bodens Sie kosten würde. Die Kosten bleiben dabei überschaubar.
            Wählen Sie einfach aus unserer breiten Produktpalette den passenden
            Boden aus und erhalten sie von uns neben ein Muster auch gleich ein
            Festpreisangebot. Mit im Preis inbegriffen sind dabei sämtliche
            Arbeiten, die das Verlegen des Parketts betreffen.
          </p>
        </div>
      </div>
      <div className="flex pt-14">
        <div>
          <AiOutlineDoubleRight size={30} />
        </div>
        <div className="pl-6 max-w-2xl lg:max-w-4xl xl:max-w-lg 2xl:max-w-2xl">
          <p className="text-2xl 2xl:text-4xl">Professionelle Ausführung</p>
          <p className="pt-1 2xl:text-xl">
            Unsere Profi-Parkettleger verlegen jeden Bodenbelag in Ihren
            Räumlichkeiten – vom Parkett, Laminat, Designboden, Kork bis hin zum
            Teppichboden. Für ihr Zuhause nutzen wir dabei nur Böden aus
            hochwertigen Materialien. Wählen Sie aus einer breiten
            Angebotspalette Ihren Lieblingsboden aus, den wir für Sie verlegen
            werden. Freuen Sie sich anschließend über einen hochwertigen
            Bodenbelag in Ihren Räumlichkeiten.
          </p>
        </div>
      </div>
    </div>
  );
}
