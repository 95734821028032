import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Content from "./Content";
import Push from "./Push";
import Slider1 from "../assets/Slider.jpg";

const Slider = () => {
  let location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);
  return (
    <div name="projekte" id="projekte">
      <div className="relative">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover blur-sm"
            src={Slider1}
            alt=""
          />
          <div
            className="absolute inset-0 bg-[#4f4540] h-full w-full mix-blend-multiply"
            aria-hidden="true"
          />
        </div>
        <div className="relative max-w-7xl 2xl:max-w-[1450px] mx-auto py-10 md:py-14 px-6 sm:px-6 lg:px-8 ">
          <div className="xl:flex">
            <div className=" text-white">
              <Content />
            </div>
            <div className="lg:pl-24 2xl:pl-20">
              <div className=" w-[320px] lg:w-[470px]  object-cover">
                <h1 className="text-white text-3xl font-bold pl-[20px] sm:pl-[60px] pt-5">
                  Referenzen
                </h1>
                <Push />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
