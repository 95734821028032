import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Uns1 from "../assets/unsere1.jpg";
import Uns2 from "../assets/unsere2.jpg";
import Uns3 from "../assets/unsere3.jpg";

export default function Unsere() {
  let location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <div
      name="leistungen"
      id="leistungen"
      className="pt-10  pb-16 lg:pb-36 bg-[#FFF6EC]"
    >
      <div className="max-w-7xl 2xl:max-w-[1450px] mx-auto px-6 lg:px-8 mb-16">
        <div className="lg:text-center">
          <p className="text-3xl md:text-4xl 2xl:text-6xl font-bold tracking-tight text-center text-[#2B2C2B]">
            Unsere Leistungen
          </p>
          <p className="text-center text-md pt-5 lg:max-w-4xl 2xl:text-2xl mx-auto">
            Entdecken Sie unsere große Auswahl an hochwertigen Parkett-, Design-
            und Teppichböden. Wir bieten nur Bodenbeläge von höchster Qualität.
            Als professionelle Bodenleger aus dem Raum Bocholt bieten wir
            folgende Leistungen an:
          </p>
        </div>
      </div>
      <div>
        <div className="max-w-7xl 2xl:max-w-[1450px] mx-auto px-4 sm:px-6 lg:px-8 grid gap-16 ">
          <div className="xl:flex max-w-2xl mx-auto xl:max-w-[1450px]  gap-16 items-center shadow-xl rounded-2xl bg-white px-4 py-4">
            <img
              src={Uns1}
              alt=""
              className="h-56 sm:h-80 w-full object-cover rounded-xl"
            />
            <div>
              <h1 className="pt-3 2xl:pt-0 pb-5 md:pb-4 2xl:pb-4 text-2xl 2xl:text-3xl font-bold text-center text-[#2B2C2B]">
                Fachgerechte Verlegung
              </h1>

              <p className="text-center md:text-start  2xl:text-xl text-[#2B2C2B]">
                Sie sind auf der Suche nachdem einem Fachmann für
                Bodenrenovierung? Dann sind Sie bei uns genau richtig. Ob Sie
                nur ein Zimmer renovieren möchten oder eine komplette
                Bodensanierung aller Räume benötigen, wir liefern Ihnen eine
                zuverlässige und saubere Arbeit ab. Nach der Bodenverlegung
                erstrahlt Ihr Parkett wieder in neuem Glanz und sorgt für eine
                schöne Optik in allen Räumen. Unsere Profi-Parkettleger verlegen
                jeden Bodenbelag in Ihren Räumlichkeiten – vom Parkett, Laminat,
                Designboden, Kork bis hin zum Teppichboden. Für ihr Zuhause
                nutzen wir dabei nur Böden aus hochwertigen Materialien. Wählen
                Sie aus einer breiten Angebotspalette Ihren Lieblingsboden aus,
                den wir für Sie verlegen werden. Freuen Sie sich anschließend
                über einen hochwertigen Bodenbelag in Ihren Räumlichkeiten.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
            <div className="gap-16 items-center bg-white shadow-xl rounded-2xl px-4 py-4">
              <img
                src={Uns2}
                alt=""
                className="h-56 sm:h-56 2xl:h-72 w-full rounded-xl object-cover"
              />
              <h1 className="md:px-9 pt-3 pb-5 md:pb-4 text-2xl 2xl:text-3xl font-bold text-center text-[#2B2C2B]">
                Reparatur und Aufbereitung
              </h1>

              <p className=" text-center md:text-start 2xl:text-xl  md:px-9 text-[#2B2C2B]">
                Ihr Parkettboden muss erneut werden und zeigt deutliche Spuren
                der Abnutzung? Es gibt mehre gute Gründe Ihren alten Holzboden,
                der in die Jahre gekommen ist, aufbereiten zu lassen. Beim
                Aufbereiten wird der Boden zunächst abgeschliffen und einzelne
                Kratzer und Verfärbungen werden zuverlässig entfernt. Für einen
                dauerhaften Schutz wird der Boden von uns versiegelt oder
                eingeölt. Die Versieglung verhindert dabei, dass Schmutz in den
                Boden gelangt. Auch das Einölen schützt ihr Parkett und erhält
                die natürliche Optik und Haptik über längere Zeit. Wir arbeiten
                mit den neusten handwerklichen Techniken, bei denen nur Lacke
                und Öle von höchster Qualität Verwendung finden.
              </p>
            </div>
            <div className="gap-16 items-center shadow-xl bg-white rounded-2xl px-4 py-4 ">
              <img
                src={Uns3}
                alt=""
                className="h-56 sm:h-56 2xl:h-72 object-cover w-full rounded-xl"
              />
              <h1 className=" md:px-9 pt-3 pb-5 md:pb-4 text-2xl 2xl:text-3xl font-bold text-center text-[#2B2C2B]">
                Treppensanierung
              </h1>

              <p className="text-center md:text-start 2xl:text-xl  md:px-9 text-[#2B2C2B]">
                Eine alte Holztreppe muss nicht immer gleich herausgerissen
                werden. Mit den richtigen Mitteln lassen wir alte Treppen in
                neuem Glanz erstrahlen. Dabei verwenden wir die neusten
                handwerklichen Techniken, die neusten Schleifmaschinen und
                Sauggeräte, um Staub großenteils zu vermeiden. Eine
                Treppensanierung ist mit viel Schmutz und Lärm verbunden, das
                muss nicht sein, wir verleihen ihrer Treppe neuen Glanz. Dabei
                bieten wir zahlreiche Gestaltungsmöglichkeiten für ihre
                Treppensanierung in hochwertiger Optik durch Verkleidung der
                Podeste, sowie der Setz– und Trittstufen mit Parkett, PVC,
                Holzdielen, Laminat, Teppich und Linoleum.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
