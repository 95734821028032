import "flowbite";
import { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import { BsTelephone } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import Selo1 from "../assets/Selo1.png";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function Contact() {
  const [verified, setVerified] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const form = useRef();

  function onChange() {
    setVerified(true);
  }
  const handleModal = () => {
    setShowModal(true);

    setTimeout(() => {
      setShowModal(false);
    }, 5000);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_JS_SERVICE,
        process.env.REACT_APP_EMAIL_JS_TEMPLATE,
        form.current,
        process.env.REACT_APP_EMAIL_JS_USER
      )
      .then(
        ({ status }) => {
          if (status === 200) {
            handleModal();
            setLoading(false);
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  let location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);
  return (
    <div name="kontakt" id="kontakt" className="bg-[#DFE0DF] md:pt-5">
      <div className="px-6 max-w-6xl 2xl:max-w-[1450px]  md:mx-auto pt-10 md:flex md:justify-between">
        <div className="">
          <div>
            <img
              className="h-12 mt-2 mb-3  md:h-14 2xl:h-16"
              src={Selo1}
              alt=""
            />

            <a
              href="https://www.google.com/maps/place/Efeuweg+5,+46399+Bocholt,+Almanya/@51.8322156,6.5847214,17z/data=!3m1!4b1!4m5!3m4!1s0x47b88757347cfb65:0xfc83607df5f3de59!8m2!3d51.8322156!4d6.5869101"
              target="_blank"
              className="mt-5 text-xl 2xl:text-2xl text-[#3A210D]"
            >
              Efeuweg 5 <br /> 46395 Bocholt
            </a>
            <a
              href="tel:+4917670086325"
              className="flex items-center gap-4 mt-8 text-xl 2xl:text-2xl text-[#3A210D]"
            >
              <BsTelephone />
              +49 176 70086325
            </a>
            <a
              href="mailto:sel.o@gmx.de"
              className="flex items-center text-xl 2xl:text-2xl gap-4 pb-5 text-[#3A210D]"
            >
              <FiMail />
              sel.o@gmx.de
            </a>
            <div className="grid grid-cols-1">
              <div className="flex items-center gap-3">
                <AiOutlineDoubleRight />
                <p className="text-2xl text-[#3A210D]">
                  <Link to="/impressum">Impressum</Link>
                </p>
              </div>

              <div className="flex items-center gap-3">
                <AiOutlineDoubleRight />
                <p className="text-2xl text-[#3A210D]">
                  <Link to="/datenschtuz">Datenschutz</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-10">
          <div className="pr-5 max-w-5xl lg:max-w-2xl md:px-16 mx-auto">
            <form
              ref={form}
              onSubmit={sendEmail}
              className="grid gap-4 grid-cols-1"
            >
              <div>
                <h1 className="mt-10 md:mt-0 text-5xl font-bold text-[#3A210D]">
                  Kontakt
                </h1>
              </div>
              <div className="relative">
                <input
                  type="text"
                  id="name"
                  name="name"
                  required
                  className="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent bg-white rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                />
                <label
                  htmlFor="name"
                  className="absolute text-sm text-gray-500 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-[#3A210D] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                >
                  Name
                </label>
              </div>
              <div className="relative">
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  className="block bg-white  px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                />
                <label
                  htmlFor="email"
                  className="absolute text-sm text-gray-500 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-[#3A210D] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                >
                  E-Mail
                </label>
              </div>
              <div className="relative">
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  required
                  className="block bg-white  px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                />
                <label
                  htmlFor="phone"
                  className="absolute text-sm text-gray-500 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-[#3A210D] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                >
                  Telefon
                </label>
              </div>
              <div className="relative">
                <textarea
                  id="message"
                  name="message"
                  required
                  rows={4}
                  className="block px-2.5 bg-white  pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                />
                <label
                  htmlFor="message"
                  className="absolute text-sm text-gray-500 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-[#3A210D] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-10 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                >
                  Ihre Nachricht
                </label>
              </div>
              <div className="flex">
                <div className="flex items-center h-5">
                  <input
                    type="checkbox"
                    id="checkbox"
                    name="checkbox"
                    required
                    aria-describedby="helper-checkbox-text"
                    value="Ich willige ein, dass SELO Parkett- & Fußbodenverlegung die von mir zur
                    Verfügung gestellten persönlichen Daten zur Bearbeitung
                    meiner Anfrage elektronisch verarbeitet und mich
                    kontaktiert."
                    className="w-4 h-4 text-black bg-gray-100 rounded border-gray-300 focus:ring-black dark:focus:ring-black dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </div>
                <div className="ml-2 text-sm">
                  <label
                    htmlFor="checkbox"
                    className="font-medium text-sm text-gray-900"
                  >
                    Mit den Bestimmungen zum{" "}
                    <span className="font-semibold underline hover:text-[#3A210D]">
                      <a href="" className="pr-1">
                        <Link to="/datenschtuz">Datenschutz</Link>
                      </a>
                    </span>
                    bin ich einverstanden.
                  </label>
                  <p
                    id="helper-checkbox-text"
                    className="text-sm font-normal text-gray-900"
                  >
                    Ich willige ein, dass SELO Parkett- & Fußböden die von mir
                    zur Verfügung gestellten persönlichen Daten zur Bearbeitung
                    meiner Anfrage elektronisch verarbeitet und mich
                    kontaktiert.
                  </p>
                </div>
              </div>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                onChange={onChange}
              />
              {showModal && (
                <div className="rounded-md bg-green-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <svg
                        className="h-5 w-5 text-green-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-green-800">
                        Ihre Nachricht wurde erfolgreich übermittelt.
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {!isLoading && !verified && (
                <button
                  disabled
                  type="submit"
                  className="text-white inline-block bg-[#7a6752] dark:bg-[#BBA89C] cursor-not-allowed font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Senden
                </button>
              )}
              {!isLoading && verified && (
                <button
                  type="submit"
                  className="text-white bg-[#3A210D]  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                >
                  Senden
                </button>
              )}
              {isLoading && (
                <button
                  disabled
                  type="button"
                  className="text-white bg-[#3A210D] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center items-center"
                >
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline mr-3 w-4 h-4 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  <p className="bg-[#3A210D]">
                    Ihre Nachricht wird gesendet...
                  </p>
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
