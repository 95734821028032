import React, { useEffect } from "react";
import NavbarTest from "../components/NavbarTest";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

export default function Impressum() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <NavbarTest />
      <div className="mx-8 sm:mx-16 xl:max-w-5xl xl:mx-auto py-10">
        <h1 className="text-4xl lg:text-5xl font-bold">Impressum</h1>

        <h2 className="text-2xl mt-5 font-bold">
          Angaben gem&auml;&szlig; &sect; 5 TMG
        </h2>
        <p className="text-lg mt-4">
          Selatdin Özkan
          <br />
          SELO Parkett- und Fußböden
          <br />
          Efeuweg 5
          <br />
          46395 Bocholt
        </p>

        <h2 className="text-2xl mt-5 font-bold">Kontakt</h2>
        <p className="text-lg mt-4">
          Telefon: +49 176 70086325
          <br />
          E-Mail: sel.o@gmx.de
        </p>

        <h2 className="text-2xl mt-5 font-bold">Umsatzsteuer-ID</h2>
        <p className="text-lg mt-4">
          Umsatzsteuer-Identifikationsnummer gemäß 27 a Umsatzsteuergesetz:
          <br />
          DE815151221
        </p>

        <h2 className="text-2xl mt-5 font-bold">EU-Streitschlichtung</h2>
        <p className="text-lg mt-4">
          Die Europäische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit:{" "}
          <a
            href="https://ec.europa.eu/consumers/odr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://ec.europa.eu/consumers/odr/
          </a>
          .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
        </p>

        <h2 className="text-2xl mt-5 font-bold">
          Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
        </h2>
        <p className="text-lg mt-4">
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>

        <p className="text-lg mt-4">
          Quelle:{" "}
          <a href="https://www.e-recht24.de">https://www.e-recht24.de</a>
        </p>
      </div>
      <Contact />
      <Footer />
    </div>
  );
}
